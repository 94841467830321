<template>
  <div class="approve">
    <div class="approve-top">
      <div class="tea-search">
        <el-form ref="form" :inline="true" class="demo-form-inline">
          <!-- 兑换物品 -->
          <el-form-item label="兑换物品:">
            <el-select v-model="search_data.wpid" placeholder="请选择" clearable @change="handelSearch">
              <el-option :value="item.id" :label="item.mc" v-for="(item,index) in goods_options" :key='index'></el-option>
            </el-select>
          </el-form-item>
          <!-- 审核结果 -->
          <el-form-item label="兑换进度:">
            <el-select v-model="search_data.dhjd" placeholder="请选择" @change="handelSearch">
              <el-option :value="0" label="未发货" :key="0"> </el-option>
              <el-option :value="1" label="已发货" :key="1"></el-option>
              <el-option :value="2" label="已收货" :key="2"> </el-option>
              <el-option :value="3" label="暂缓发货" :key="3"> </el-option>
            </el-select>
          </el-form-item>
          <!-- 搜索框 -->
          <el-form-item>
            <div class="search-text" style="width:300px">
              <el-input placeholder="请输入兑换物品或学生名称查询" v-model="search_data.mc" class="input-with-select">
              </el-input>
            </div>
          </el-form-item>
          <!-- 查询按钮 -->
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" class="fl tea-btn" @click="handelSearch">查 询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table">
      <!-- tableData -->
      <el-table stripe border :data="tableData" height="calc(100% - 20px)" @sort-change="changeTableSort">
        <el-table-column label="序号" type="index" align="center" :index="indexMethod" width="80" />
        <el-table-column prop="wpmc" label="兑换物品" align="center" width="200"/>
        <el-table-column prop="sl" label="兑换数量" align="center" />
        <el-table-column prop="dhrq" label="兑换申请时间" align="center" sortable>
          <template v-slot="scope">
            <span>{{new Date(scope.row.dhrq).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="xsmc" label="学生姓名" align="center" width="100"/>
        <el-table-column prop="bh" label="物品编号" align="center" />
        <el-table-column prop="dhjd" label="兑换进度" align="center" width="100">
          <template v-slot="scope">
            <el-tag v-show="scope.row.dhjd==0">待发货</el-tag>
            <el-tag type="success" v-show="scope.row.dhjd==1">已发货</el-tag>
            <el-tag type="info" v-show="scope.row.dhjd==2">已收货</el-tag>
            <el-tag type="warning" v-show="scope.row.dhjd==3">暂缓发货</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="fhrq" label="物品寄送时间" align="center" sortable>
          <template v-slot="scope">
            <span>{{new Date(scope.row.fhrq).Format("yyyy-MM-dd")}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="bz" label="备注" align="center" />
        <el-table-column label="操作" align="center" width="100px" v-if="search_data.dhjd!=2">
          <template v-slot="scope">
            <el-button size="mini" :disabled='search_data.dhjd!=1' icon="el-icon-check" type="primary" class="fl tea-btn" @click="confirmReceipt(scope.row)">确认收货</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="tea-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pages.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pages.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pages.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import {
  exchangeApproveListApi,//审核列表
  exchangeGoodsOptionsApi,//物品下拉
  exchangeConfirmReceiptApi,//确认收货
} from '@/api/teacher'
export default {
  name: 'approve',
  components: {
  },
  data() {
    return {
      yhid: this.$store.state.userInfo.yhid,
      zhid: this.$store.state.userInfo.zhid,
      search_data: {
        shzt: 1,
        dhjd: 1,
        zhid: this.$store.state.userInfo.zhid,
      },
      pages: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      goods_options: [],
      tableData: [],
    }
  },
  watch: {
  },
  created() {
    this.getGoodsOptions()
  },
  computed: {
  },
  mounted() {
    this.getExchangeApproveList()
  },
  methods: {
    // 获取下拉框选项
    getGoodsOptions() {
      exchangeGoodsOptionsApi({}).then(res => {
        if (res.result) {
          this.goods_options = res.result
        }
      })
    },
    // 查询列表
    getExchangeApproveList() {
      const data = this.search_data;
      data.currentPage = this.pages.currentPage;
      data.pageSize = this.pages.pageSize;
       data.yycdid=this.$store.state.yycdid;
      exchangeApproveListApi(data).then(res => {
        if (res.result) {
          this.pages.total = res.result.total
          this.tableData = res.result.list
        }
      })
    },
    // 计算序号
    indexMethod(index) {
      return (this.pages.currentPage - 1) * this.pages.pageSize + index + 1;
    },
    // 排序
    changeTableSort(column) {
      this.search_data.dhrqpx = null
      this.search_data.fhrqpx = null
      if (column.prop == "dhrq") {
        // 兑换申请时间
        if (column.order == 'ascending') {
          // 升序
          this.search_data.dhrqpx = 1
        } else if (column.order == 'descending') {
          // 降序
          this.search_data.dhrqpx = 2
        }
      } else if (column.prop == 'fhrq') {
        // 发货时间
        if (column.order == 'ascending') {
          // 升序
          this.search_data.fhrqpx = 1
        } else if (column.order == 'descending') {
          // 降序
          this.search_data.fhrqpx = 2
        }
      }
      this.handelSearch()
    },
    // ------------------------------------------------------分页器
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.handelSearch()
    },
    //  -----------------------------------------------------分页器
    handleCurrentChange(val) {
      this.pages.currentPage = val;
      this.getExchangeApproveList()
    },
    // 点击查询
    handelSearch() {
      // 点击查询，页数，每页显示个数，初始化
      this.pages.currentPage = 1;
      this.getExchangeApproveList()
    },
    // 确认收货
    // 审核提交
    confirmReceipt(result) {
      this.$confirm('确定提交该审批吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const data = {
          dhjd: 2,
          id: result.id
        }
        // data.zhid = this.zhid;
        data.shrxm = this.$store.state.userInfo.realname
        data.shrid = this.$store.state.userInfo.yhid
        data.shrq = new Date()
        exchangeConfirmReceiptApi(data).then(res => {
          if (res.result) {
            if (res.success) {
              this.$message.success('已确认收货')
              this.getExchangeApproveList()
            }
          }
        })
      })
    },
    // 
  }
}
</script>
<style lang="scss" scoped>
.approve {
  height: 100%;
  .table {
    height: calc(100% - 100px);
    .tea-pagination {
      margin-top: 15px;
    }
    .el-tabs--border-card {
      border: 1px solid #ebeef5;
      box-shadow: none;
      border-bottom: none;
      /deep/.el-tabs__header {
        border-bottom: none !important;
      }
    }
  }
}
.approve-top {
  padding-top: 10px;
}
.examine-record {
  padding-top: 20px;
  border-top: #eee solid 1px;
  .title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: #409eff;
    margin-bottom: 10px;
  }
}
.tea-btn {
  &.el-button--primary {
    background-color: #5186c2;
    border-color: #5186c2;
  }
  &.is-disabled {
    opacity: 0.8;
  }
}
</style>



